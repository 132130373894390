<template>
  <v-app>
    <v-main class="app">
      <router-view />
    </v-main>
  </v-app>
</template>

<style scoped>
main {
  position: relative !important;
}
</style>